import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./page/home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import About from "./page/about";
import Servicespage from "./components/servicespage/servicespage";
import Portfolio from "./components/portfolio/portfolio";
import Contact from "./page/contact";
import ScrollToTop from "./components/scroll/scroll";
import Quantum from "./page/quantum";
import IOT from "./page/iot";
import Pricing from "./page/pricing";



function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Servicespage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/quantum" element={<Quantum />} />
        <Route path="/iot" element={<IOT />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
