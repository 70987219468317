import React from 'react';
import Animatedheading from '../animatedheading/animatedheading';
import { useTranslation } from 'react-i18next';
import blockchainIcon from '../../assets/icons/blockchain.svg';
import coin from '../../assets/icons/coin.svg';
import athcoin from '../../assets/icons/athCoin.svg';
import bitcoin from '../../assets/icons/bitcoin.svg';
import check from '../../assets/icons/checked.svg';

import './blockchain.scss';

const BlockChain = () => {
  const { t } = useTranslation();

  const firstCard = [
    { id: 1, dsc: 'Chatbot using no code', price: '3,000 USD' },
    { id: 2, dsc: 'Custom Chatbot', price: '6,000 USD' },
    { id: 3, dsc: 'RAG Based Chatbot', price: '7,500 USD' },
    { id: 4, dsc: 'Omnichannel Chatbot', price: '8,500 USD' },
    { id: 5, dsc: 'AI Calling Agent using No Code', price: '6,000 USD' },
    {
      id: 6,
      dsc: 'Custom AI Calling Agent',
      price: '9,500 USD, 12,500 USD For Solana',
    },
  ];
  const secondCard = [
    { id: 1, dsc: 'Chatbot using no code', price: '3,000 USD' },
    { id: 2, dsc: 'Custom Chatbot', price: '6,000 USD' },
    { id: 3, dsc: 'RAG Based Chatbot', price: '7,500 USD' },
    { id: 4, dsc: 'Omnichannel Chatbot', price: '8,500 USD' },
    { id: 5, dsc: 'AI Calling Agent using No Code', price: '6,000 USD' },
    {
      id: 6,
      dsc: 'Custom AI Calling Agent',
      price: '9,500 USD, 12,500 USD For Solana',
    },
  ];

  return (
    <div className="blockchain-sec">
      <div className="heading">
        <Animatedheading
          innerfirst={t('pricing.blockchain.outer')}
          innersecond={t('pricing.blockchain.inner')}
          outer="Development"
        />
      </div>
      <div className="blockchain">
        <div className="top">
          <div className="logo">
            <img src={blockchainIcon} alt="blockchain-icon" />
          </div>
          <div>
            <p className="category">For individuals</p>
            <p className="title">BLOCKCHAIN PROJECTS</p>
            <p className="dsc">
              Get secure, scalable blockchain solutions with seamless
              integration, ongoing updates, and expert support—built for the
              future of digital trust!
            </p>
          </div>
        </div>
        <div className="items">
          <div className="item">
            {firstCard.map(({ id, dsc, price }) => (
              <div className="project-types" key={id}>
                <div className="left">
                  <img src={check} alt="check icon" />
                  <p>{dsc}</p>
                </div>
                <p className="price">{price}</p>
              </div>
            ))}
          </div>
          <div className="item">
            {secondCard.map(({ id, dsc, price }) => (
              <div className="project-types" key={id}>
                <div className="left">
                  <img src={check} alt="check icon" />
                  <p>{dsc}</p>
                </div>
                <p className="price">{price}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="btn">
          <button className="appointmnet-btn">
            <a
              href="https://calendly.com/octaloop-technologies/30min"
              target="_blank"
            >
              Book Your Appointment
            </a>
          </button>{' '}
        </div>
        <img src={coin} alt="coin-img" className="coin" />
        <img src={athcoin} alt="coin-img" className="athcoin" />
        <img src={bitcoin} alt="coin-img" className="bitcoin" />
      </div>
    </div>
  );
};

export default BlockChain;
