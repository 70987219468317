import React from 'react';
import { useTranslation } from 'react-i18next';
import './project.scss';

import AiProject from './AiProject';
import BlockChain from './BlockChain';
import ArVr from './ArVr';
import Web from './Web';

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div className="projects-price">
      <Web />
      <AiProject />
      <BlockChain />
      <ArVr />
    </div>
  );
};

export default Projects;
