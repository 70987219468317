import React from 'react';
import { useTranslation } from 'react-i18next';
import Animatedheading from '../animatedheading/animatedheading';
import check from '../../assets/icons/checked.svg';

import './arvr.scss';

// images
import arvrLogo from '../../assets/icons/arLogo.svg';
import blueBg from '../../assets/img/blueBg.png';
import arbot from '../../assets/img/ar.png';
import diamond1 from '../../assets/icons/diamond1.svg';
import diamond2 from '../../assets/icons/diamond2.svg';
import diamond3 from '../../assets/icons/diamond3.svg';

const ArVr = () => {
  const { t } = useTranslation();

  const payment = [
    {
      id: 1,
      dsc: 'Floor Plans Generation Viewing in AR Environments',
      price: '1,000 USD',
    },
    {
      id: 2,
      dsc: 'Room Scanning + Surface Selection + 3D Model Exports',
      price: '500 USD',
    },
    {
      id: 3,
      dsc: 'Motion Tracking and 3D Video Recommendation',
      price: '1,200 USD',
    },
    {
      id: 4,
      dsc: 'Room Scanning + Object Overlays (e.g. Furniture, Paintings)',
      price: '10,000 USD',
    },
  ];

  return (
    <div className="ar-vr-sec">
      <div className="heading">
        <Animatedheading
          innerfirst={t('pricing.arvr.outer')}
          innersecond={t('pricing.arvr.inner')}
          outer="Development"
        />
      </div>

      <div className="content">
        {/* Background Image */}
        <img src={blueBg} alt="background" className="bg-img" />

        {/* Left Section */}
        <div className="left">
          <div className="top">
            <div className="img">
              <img src={arvrLogo} alt="brows-img" className="brows-img" />
            </div>
            <div>
              <p className="head">For individuals</p>
              <h3>AR/VR PROJECTS</h3>
              <p className="dsc">
                Get immersive AR/VR experiences with seamless development,
                regular updates, and expert support—enhancing innovation and
                engagement!
              </p>
            </div>
          </div>

          <div className="bottom">
            <div className="items">
              <div className="item">
                {payment.map(({ id, dsc, price }) => (
                  <div className="project-types" key={id}>
                    <div className="left-item">
                      <img src={check} alt="check icon" />
                      <p>{dsc}</p>
                    </div>
                    <div className="pr">
                      <p className="price">{price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            <div className="img">
              <img src={arbot} alt="ar-bot" className="arBot" />
            </div>
            <div className="btn">
              <button className="appointmnet-btn">
                <a
                  href="https://calendly.com/octaloop-technologies/30min"
                  target="_blank"
                >
                  Book Your Appointment
                </a>
              </button>{' '}
            </div>

            <img src={diamond1} alt="diamond1" className="diamond1" />
            <img src={diamond2} alt="diamond2" className="diamond2" />
            <img src={diamond3} alt="diamond3" className="diamond3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArVr;
