import React from 'react';
import Banner from '../components/shared/banner';
import { useTranslation } from 'react-i18next';
import Projects from '../components/pricing/Projects';

import '../components/pricing/pricing.scss';
const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="pricing">
      <Banner
        head={t('pricing.banner.primary_heading')}
        subHead2={t('pricing.banner.secondary_heading')}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/quantum_poster.webp"
      />
      <div className="projects">
        <Projects />
      </div>
    </div>
  );
};

export default Pricing;
