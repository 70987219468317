import React from 'react';
import Animatedheading from '../animatedheading/animatedheading';
import { useTranslation } from 'react-i18next';
import check from '../../assets/icons/checked.svg';
import chatbotImg from '../../assets/icons/chatbot.svg';
import bulb from '../../assets/icons/bulb.svg';
import graph from '../../assets/icons/graph.svg';
import avtar from '../../assets/icons/avtar.svg';
import ocrImg from '../../assets/icons/ocr.svg';
import blueBg from '../../assets/img/blueBg.png';
import bot from '../../assets/img/aiBot.png';
import './ai.scss';

const AiProject = () => {
  const { t } = useTranslation();

  const pricingData = [
    {
      id: 'chatbot',
      title: 'AI Chat Bots',
      img: chatbotImg,
      category: 'For individuals',
      items: [
        { id: 1, dsc: 'Chatbot using no code', price: '3,000 USD' },
        { id: 2, dsc: 'Custom Chatbot', price: '6,000 USD' },
        { id: 3, dsc: 'RAG Based Chatbot', price: '7,500 USD' },
        { id: 4, dsc: 'Omnichannel Chatbot', price: '8,500 USD' },
        { id: 5, dsc: 'AI Calling Agent using No Code', price: '6,000 USD' },
        { id: 6, dsc: 'Custom AI Calling Agent', price: '12,000 USD' },
      ],
    },
    {
      id: 'marketing',
      title: 'AI Marketing/CRMs',
      img: bulb,
      category: 'For businesses',
      items: [
        {
          id: 1,
          dsc: 'Automated Engagement on Social Media',
          price: '7,500 USD',
        },
        { id: 2, dsc: 'Affiliate Marketing Automation', price: '8,500 USD' },
        { id: 3, dsc: 'AI SEO Automation SaaS', price: '14,500 USD' },
        {
          id: 4,
          dsc: 'Email Automation and CRM Integration',
          price: '7,000 USD',
        },
        { id: 5, dsc: 'AI Educational Platform', price: '12,000 USD' },
      ],
    },
    {
      id: 'scraping',
      title: 'AI Scraping/Lead Gen',
      img: graph,
      category: 'For analysts',
      items: [
        {
          id: 1,
          dsc: 'Automated Scraping Up to 5 Websites',
          price: '5,000 USD',
        },
        { id: 2, dsc: 'Fully Automated Scraping Tool', price: '7,500 USD' },
        { id: 3, dsc: 'News Aggregation & Summarization', price: '9,500 USD' },
        {
          id: 4,
          dsc: 'Automated Lead Generation & Scraping',
          price: '12,500 USD',
        },
      ],
    },
    {
      id: 'avatar',
      title: 'AI Avatars',
      img: avtar,
      category: 'For content creators',
      items: [
        { id: 1, dsc: 'AI Talking Avatar (Face Only)', price: '6,000 USD' },
        {
          id: 2,
          dsc: 'AI Talking Avatar (Upper Body Gestures)',
          price: '10,000 USD',
        },
        {
          id: 3,
          dsc: 'AI Talking Avatar (Full Body, Editable BG)',
          price: '15,000 USD',
        },
      ],
    },
    {
      id: 'ocr',
      title: 'OCR Solutions',
      img: ocrImg,
      category: 'For enterprises',
      items: [
        { id: 1, dsc: 'OCR into Existing Website', price: '35,000 USD' },
        {
          id: 2,
          dsc: 'Full OCR-Based Solution & Integration',
          price: '8,500 USD',
        },
      ],
    },
  ];

  return (
    <div className="ai-project">
      <Animatedheading
        innerfirst={t('pricing.aiHeadings.outer')}
        innersecond={t('pricing.aiHeadings.inner')}
        outer="Development"
      />

      <div className="pricingPlan">
        {pricingData.map(({ id, title, img, category, items }) => (
          <div key={id} className="pricing-card">
            <div className="top">
              <div className="icon">
                <img src={img} alt={title} />
              </div>
              <div>
                <p className="category">{category}</p>
                <p className="title">{title}</p>
              </div>
            </div>
            <div className="content">
              {items.map(({ id, dsc, price }) => (
                <div key={id} className="items">
                  <div className="left-item">
                    <img src={check} alt="check icon" />
                    <p>{dsc}</p>
                  </div>
                  <p className="price">{price}</p>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="book-appointment">
          <img src={blueBg} alt="background" className="bg-img" />
          <div className="content">
            <button className="appointmnet-btn">
              <a
                href="https://calendly.com/octaloop-technologies/30min"
                target="_blank"
              >
                Book Your Appointment
              </a>
            </button>{' '}
            <img src={bot} alt="bot" className="bot" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiProject;
