import React from 'react';
import Animatedheading from '../animatedheading/animatedheading';
import { useTranslation } from 'react-i18next';

// Images
import blueCheck from '../../assets/icons/blueCheck.svg';
import blueBg from '../../assets/img/blueBg.png';
import brows from '../../assets/icons/brows.svg';
import arrow from '../../assets/service-cards/arrow.webp';
import calender from '../../assets/img/calender.png';

const Web = () => {
  const { t } = useTranslation();

  return (
    <div className="projects-price">
      <div className="webApp">
        <Animatedheading
          innerfirst={t('pricing.webAppHeading.outer')}
          innersecond={t('pricing.webAppHeading.inner')}
          outer="Development"
        />
        <div className="content">
          {/* Background Image */}
          <img src={blueBg} alt="background" className="bg-img" />

          <div className="card">
            {/* Left Section */}
            <div className="left">
              <div className="top">
                <div className="browsImg">
                  <img src={brows} alt="brows-img" className="brows-img" />
                </div>
                <div>
                  <p className="head">For individuals</p>
                  <h3>WEB AND MOBILE APP DEVELOPMENT</h3>
                  <p className="dsc">
                    Get a custom-built, high-performance app with ongoing
                    updates, security, and support. We handle everything from
                    design to integrations, ensuring a smooth and scalable
                    solution for your business!
                  </p>
                </div>
              </div>

              <div className="bottom">
                <div className="items">
                  {[
                    {
                      text: 'Simple web app development (no AI)',
                      price: '3,500 USD',
                    },
                    {
                      text: 'Simple native mobile app development (no AI)',
                      price: '7,000 USD',
                    },
                    {
                      text: 'Advanced web app development (with AI)',
                      price: '10,000 USD',
                    },
                  ].map((item, index) => (
                    <div className="item" key={index}>
                      <div className="item-left">
                        <img
                          src={blueCheck}
                          alt="blue-check"
                          className="check"
                        />
                        <p className="large">{item.text}</p>
                      </div>
                      <h5 className="price">{item.price}</h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="right">
              <img src={calender} alt="calender-pic" />
            </div>
          </div>
          {/* Appointment Section */}
          <div className="appointment">
            <div className="typo">
              <p>
                For more information, book your appointment with our experts
              </p>
              <img src={arrow} alt="arrow" />
            </div>
            <div className="btn">
              <button className="appointmnet-btn">
                <a
                  href="https://calendly.com/octaloop-technologies/30min"
                  target="_blank"
                >
                  Book Your Appointment
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Web;
